<template>
	<div>
		<SelectContract />
		<SelectContractLabel />
		<BCard>
			<BCardHeader>
				<BCardTitle class="text-center">
					Solicitar demarcação de lote
				</BCardTitle>
			</BCardHeader>
			<BCardBody>
				<BCardText class="text-justify">
					<h5>O que é a demarcação?</h5>
					<p>
						A demarcação de lote é quando você cliente vai construir e solicita a São
						Bento que coloque marcos nos quatro cantos do seu lote para que sua equipe de
						obra consiga identificar as divisas exatas do imóvel.
					</p>
					<p>
						A demarcação é um procedimento de extrema responsabilidade, para evitar que um
						proprietário ultrapasse os limites do lote vizinho sem a intenção, por isso
						disponibilizamos nossa equipe de topografia de alta precisão sem custo algum
						para nossos clientes. No entanto, caso o lote seja demarcado e você solicite
						uma segunda demarcação, ela terá um custo de R$500,00. Por isso recomendamos
						que demarque o seu lote se realmente for construir.
					</p>
				</BCardText>
			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton variant="primary" class="col-12 col-md-4 mx-auto" @click="nextStep()">
					Quero Solicitar
				</BButton>
			</BCardFooter>
		</BCard>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardTitle,
	BCardHeader,
	BCardFooter,
	BButton,
	BCardText,
} from 'bootstrap-vue'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import SelectContract from '@/views/components/selectContract.vue'

export default {
	name: 'StepOne',
	components: {
		BCard,
		BCardBody,
		BCardTitle,
		BButton,
		BCardHeader,
		BCardFooter,
		BCardText,
		SelectContract,
		SelectContractLabel,
	},
	methods: {
		nextStep() {
			this.$emit('nextStep')
		},
	},
}
</script>
