<template>
    <BCard>
        <BCardHeader>
            <BCardTitle class="mx-auto mx-md-0 text-center">
                Solicitação de nº {{ processID }} foi realizada com Sucesso
            </BCardTitle>
        </BCardHeader>
        <BCardBody class="d-flex align-items-center flex-wrap">
            <SuccessCheck class="col-md-3 mx-auto mx-md-0 " />
            <BCardText class="text-center text-md-left col-md-9">
                <h4 class="mb-2 text-responsive">
                    Sua solicitação foi realizada com sucesso e já chegou à nossa equipe!
                </h4>
                <h4 class="text-responsive">
                    Você pode acompanhar o status da sua solicitação através do
                    menu
                    <router-link to="/minhas-solicitacoes" class="text-primary">
                        Minhas Solicitações
                    </router-link>
                    ou clicando no botão abaixo.
                </h4>
            </BCardText>
        </BCardBody>
        <BCardFooter
            class="d-flex justify-content-start justify-content-md-end"
        >
            <BButton
                variant="primary"
                class="col-12 col-md-4 mx-auto"
                @click="$router.push('minhas-solicitacoes')"
            >
                Acompanhar Solicitações
            </BButton>
        </BCardFooter>
    </BCard>
</template>

<script>
    import {
        BCard,
        BCardBody,
        BCardTitle,
        BCardHeader,
        BCardFooter,
        BButton,
        BCardText,
    } from "bootstrap-vue"
    import SuccessCheck from "@/views/components/SucessCheck.vue"

    export default {
        name: "Success",
        props: {
            processID: {
                type: String,
                required: true,
            },
        },
        components: {
            BCard,
            BCardBody,
            BCardTitle,
            BCardHeader,
            BCardFooter,
            BButton,
            BCardText,
            SuccessCheck,
        },
    }
</script>

<style>
    .text-responsive {
        font-size: 1.2rem !important;
    }
</style>
