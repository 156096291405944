<template>
	<div>
		<StepOne @nextStep="nextStep()" v-if="step === 1" />
		<StepTwo @nextStep="nextStep()" v-if="step === 2" />
		<StepThree @submitProcess="submitProcess" v-if="step === 3" />
		<StepFour :result="result" v-if="step === 4" />
	</div>
</template>
<script>
import StepOne from './steps/StepOne.vue'
import StepTwo from './steps/StepTwo.vue'
import StepThree from './steps/StepThree.vue'
import StepFour from './steps/StepFour.vue'
export default {
	name: 'SolicitacaoDemarcacaoLote',
	components: {
		StepOne,
		StepTwo,
		StepThree,
		StepFour,
	},

	data() {
		return {
			step: 1,
			lastStep: 4,
			result: null,
		}
	},

	methods: {
		nextStep() {
			if (this.step < this.lastStep) {
				this.step++
			}
		},

		submitProcess(value) {
			this.result = value
			this.nextStep()
		},
	},
}
</script>
